/**
 * Initialize Matomo on consent
 */
/*
 window.matomoLoaded = false;
 window.addEventListener('cookieConsent', function (event) {
     if (event.detail.hasOption('matomo') && window.matomoLoaded === false) {
         var _paq = (window._paq = window._paq || []);

         // tracker methods like "setCustomDimension" should be called before "trackPageView"
         _paq.push(['setDoNotTrack', true]);
         _paq.push(['trackPageView']);
         _paq.push(['enableLinkTracking']);
         (function () {
             //
             // TODO: Change matomo URL below (don’t forget the trailing slash)
             //
             var u = '//www.domain.de/matomo/';
             _paq.push(['setTrackerUrl', u + 'matomo.php']);
             _paq.push(['setSiteId', '1']);
             var d = document,
                 g = d.createElement('script'),
                 s = d.getElementsByTagName('script')[0];
             g.async = true;
             g.src = u + 'matomo.js';
             s.parentNode.insertBefore(g, s);
         })();

         window.matomoLoaded = true;
     }
 });
*/

/**
 * Component scripts
 */
/*
import '../components/molecules/consent-dialog/_consent-dialog.js';
import '../components/molecules/lazy-load/_lazy-load.js';
import '../components/molecules/lightbox/_lightbox.js';
import Accordion from '../components/molecules/accordion/_accordion.js';
import Breadcrumb from '../components/molecules/breadcrumb/_breadcrumb.js';
import Dialog from '../components/molecules/dialog/_dialog.js';
import DisclosureButton from '../components/molecules/disclosure-button/_disclosure-button.js';
import DisclosureNav from '../components/molecules/disclosure-nav/_disclosure-nav.js';
*/
import '../components/molecules/lazy-load/_lazy-load.js';

/*
import navMobile from '../components/molecules/nav-mobile/_nav-mobile.js';
import Drawer from '../components/molecules/drawer/_drawer.js';
*/
import Header from '../components/organisms/header/_header.js';
import loadingButton from '../components/atoms/button/_button.js';

/*
import Masonry from 'masonry-layout';
import Slideshow from '../components/molecules/slideshow/_slideshow.js';
import TeaserHasLink from '../components/molecules/teaser/_teaser.js';
*/
import showOnIntersect from '../components/molecules/fab/_show-on-intersect.js';

// import Toggle from '../components/molecules/toggle-button/_toggle-button.js';
import Figure from '../components/molecules/figures/_figures.js';

function initAll() {
    // navMobile();
    loadingButton();
    showOnIntersect();

    const $figureList = document.getElementsByClassName('js-count-up');
    for (const $figure of $figureList) {
        new Figure($figure).init();
    }

    /*
    const $accordionList = document.getElementsByClassName('js-accordion');
    for (const $accordion of $accordionList) {
        new Accordion($accordion).init();
    }

    const $disclosureNavElements = document.querySelectorAll('.js-disclosure-nav');
    for (const $disclosureNavElement of $disclosureNavElements) {
        new DisclosureNav($disclosureNavElement).init();
    }
    */

    const $header = document.getElementsByClassName('js-header')[0];
    let header;
    if ($header) {
        header = new Header($header);
        header.init();
    }

    /*
    let $slideShowNodes = document.querySelectorAll('.js-slideshow');

    $slideShowNodes.forEach(($slideShowNode, index, $slideShowNodes) => {
        // no slider for single images
        if ($slideShowNode.querySelectorAll('li').length !== 1) {
            new Slideshow($slideShowNode, index, $slideShowNodes).init();
        } else {
            const slideshowNav = $slideShowNode.querySelector('.c-slideshow__navigation');
            if (slideshowNav) {
                setTimeout(() => {
                    slideshowNav.style.display = 'none';
                });
            }
        }
    });

    const $breadcrumb = document.getElementsByClassName('js-breadcrumb')[0];
    if ($breadcrumb) {
        new Breadcrumb($breadcrumb).init();
    }

    const $teasersHasLink = document.getElementsByClassName('js-teaser--has-link');
    for (const $teaserHasLink of $teasersHasLink) {
        new TeaserHasLink($teaserHasLink).init();
    }

    const $masonryGrids = document.querySelectorAll('.js-grid-masonry');
    for (const $masonryGrid of $masonryGrids) {
        new Masonry($masonryGrid, {
            columnWidth: '.js-grid-sizer',
            itemSelector: '.js-grid-masonry > .js-grid-masonry__item',
        });
    }

    // percentPosition: true,
    // horizontalOrder: true,

    document.querySelectorAll('.js-dialog').forEach((node) => new Dialog(node));

    document.querySelectorAll('.js-drawer').forEach((node) => new Drawer(node));

    document.querySelectorAll('.js-disclosure-button').forEach((node) => new DisclosureButton(node).init());

    const $toggles = document.querySelectorAll('.js-toggle');
    for (const $toggle of $toggles) {
        new Toggle($toggle, $toggles).init();
    }
    */

    // TODO: Refactor global for loop into func and add sanity check that.
    const tables = document.querySelectorAll('table');
    for (const table of tables) {
        // create wrapper container
        var wrapper = document.createElement('div');

        // insert wrapper before el in the DOM tree
        table.parentNode.insertBefore(wrapper, table);

        // move el into wrapper
        wrapper.classList.add('c-table');
        wrapper.appendChild(table);
    }

    const pdfClassLinks = document.querySelectorAll('a[href$=".pdf"]');
    for (const pdfClassLink of pdfClassLinks) {
        pdfClassLink.setAttribute('target', '_blank');
    }

    const externClassLinks = document.querySelectorAll('p a[href^="http"], p a[href^="//"]');
    for (const externClassLink of externClassLinks) {
        externClassLink.setAttribute('target', '_blank');
    }

    const msoClassLinks = document.querySelectorAll('.MsoNormal');
    for (const msoClasLinks of msoClassLinks) {
        // msoClasLinks.classList.remove('MsoNormal');
        msoClasLinks.removeAttribute('class');
        msoClasLinks.removeAttribute('style');
    }

    const scrollTopBtn = document.querySelector('[js-scroll-top]');
    if (scrollTopBtn) {
        //console.log(scrollTopBtn);
        scrollTopBtn.addEventListener('click', () => {
            // Scrolling to the top of a page
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        });
    }
}

if (typeof document !== 'undefined') {
    if (document.readyState === 'loading') {
        document.addEventListener('DOMContentLoaded', initAll);
    } else {
        initAll();
    }
}
